import React, { useState } from 'react';
import { AppBar, Toolbar, Grid, IconButton, Typography, Card, CardContent, Button} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DocumentIcon from '../../assets/document_icon.png';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Player, ControlBar } from 'video-react';
import 'video-react/dist/video-react.css';

const handleBack = props => {
    props.history.goBack()
}

const styles = {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: 0,
    height: '100%',
    width: '100%'
}

const DetailPage = props => {

    let retrievedObject = localStorage.getItem('chapter');
    
    let chapter = JSON.parse(retrievedObject);

    const [open, setOpen] = useState(false);

    const handleClickOpen = (link, type) => {

        if(type == 'PDF')
            openInNewTab(link);
        else
            setOpen(link);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <>
            <AppBar position="static" color="primary">
                <Toolbar style={{ backgroundColor: '#FFF', color: '#000', textAlign: 'left' }}>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => handleBack(props)}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" style={{flexGrow: 1}}>
                        {chapter.name}
                    </Typography>

                    <Typography variant="caption">
                        {chapter.description}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid container spacing={3} style={{ marginTop: 24 }}>
                <Grid item xs={12} lg={8} style={{ margin: '0 auto', textAlign: 'left' }}>
                    
                    <Card>
                        <CardContent>
                            {
                                chapter.medias.map((row) => {
                                    return (
                                        <>
                                            <div onClick={() => handleClickOpen(row.link, row.type)}  style={{ cursor: 'pointer', display: 'flex' }}>
                                                <div style={{ marginRight: 24 }}>
                                                    <img src={DocumentIcon} style={{ height: 30 }} />
                                                </div>
                                                <div style={{ height: 35}}>
                                                    <Typography variant="subtitle2">{ row.name }</Typography>
                                                    <Typography variant="caption">{ row.description }</Typography>
                                                </div>
                                                <ChevronRightIcon style={{ marginLeft: 'auto' }} />
                                            </div>
                                            <div style={{ borderTop: '1px solid rgba(204, 204, 204, 0.5)', margin: '12px -18px 12px -18px' }} />
                                        </>
                                    );
                                })
                            }
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
            {
                open &&
                <div style={styles}>
                    <div style={{ margin: '0 auto', width: 600, paddingTop: 24}}>
                        <Player fluid={false} width={600} height={400}>
                            <source src={open} />
                            <ControlBar autoHide={false} />
                        </Player>
                        <Button variant="contained" onClick={() => handleClickClose()} style={{ marginTop: 24 }}>Fechar</Button>
                    </div>
                </div>
            }
        </>
    );
}

export default DetailPage;