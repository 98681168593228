import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';

/*
 * HTTP Link
 */
const link = new HttpLink({
    uri: 'https://luzianalanna.mobi/graphql'
});

/*
 * Auth Link
 */
const authLink = setContext((_, { headers }) => {
    
    let token = localStorage.getItem('token');

    if(!token)
        return headers;

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    authLink.concat(link)
  ]),
  cache: new InMemoryCache()
});

export default client;
