import React from 'react';
import { AppBar, Toolbar, Grid, IconButton, Typography, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const handleBack = props => {
    props.history.goBack()
}

const handleLogout = props => {
    localStorage.removeItem('token');
    localStorage.removeItem('chapter');
    props.history.push('/login');
}

const SettingsPage = props => {

    return (
        <>
            <AppBar position="static" color="primary">
                <Toolbar style={{ backgroundColor: '#FFF', color: '#000', textAlign: 'left' }}>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => handleBack(props)}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" style={{flexGrow: 1}}>
                        Configurações
                    </Typography>
                </Toolbar>
            </AppBar>
            <Grid container spacing={3} style={{ marginTop: 24 }}>
                <Grid item xs={12} lg={8} style={{ margin: '0 auto', textAlign: 'left' }}>
                    <Button color="primary" variant="contained" onClick={() => handleLogout(props)}>Logout</Button>
                </Grid>
            </Grid>
        </>
    );
}

export default SettingsPage;