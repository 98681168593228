import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import LoginPage from './pages/LoginPage/LoginPage';
import client from './apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import HomePage from './pages/HomePage/HomePage';
import PrivateRoute from './componetns/PrivateRoute/PrivateRoute';
import { MuiThemeProvider, createMuiTheme, Snackbar } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import { AppContext } from "./app-context";
import decode from "jwt-decode";
import SettingsPage from './pages/SettingsPage/SettingsPage';
import DetailPage from './pages/DetailPage/DetailPage';

const theme = createMuiTheme({
  palette: {
      primary: teal,
      secondary: teal,
    },
});

class App extends Component {
  state = {
    token: localStorage.getItem("token"),
    message: null
  };

  handleInfoUser = () => {
    if (this.state.token) return decode(this.state.token);
  };

  handleUpdateToken = token => {
    localStorage.setItem("token", token);
    this.setState({ token: token });
  };

  handleSetMessage = message => {
    this.setState({ message: message });
  };

  render() {

    return (
        <div className="App">
          <AppContext.Provider
            value={{
              getInfoUser: this.handleInfoUser,
              updateToken: this.handleUpdateToken,
              setMessage: this.handleSetMessage
            }}
          >
            <MuiThemeProvider theme={theme}>
              <ApolloProvider client={client}>
                <BrowserRouter>
                    <Switch>
                      <Route path="/login" exact component={LoginPage} />
                      <PrivateRoute path="/" exact component={HomePage} />
                      <PrivateRoute path="/settings" exact component={SettingsPage} />
                      <PrivateRoute path="/detail" exact component={DetailPage} />
                  </Switch>
                </BrowserRouter>

                {this.state.message && (
                  <Snackbar
                    open={true}
                    onClose={() => this.setState({ message: null })}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left"
                    }}
                    autoHideDuration={3000}
                    message={<span id="message-id">{this.state.message}</span>}
                  />
                )}
              </ApolloProvider>
            </MuiThemeProvider>
          </AppContext.Provider>
        </div>
    );
  }

}

export default App;