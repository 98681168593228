import React from 'react';
import gql from 'graphql-tag';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { graphql } from 'react-apollo';
import CheckIcon from '@material-ui/icons/Check';
import LockIcon from '@material-ui/icons/Lock';

const FETCH_COURSES = gql`
    query{
        fetchCourses{
            name
            image
            levels{
                name
                units{
                    id
                    name
                    locked
                    current
                }
            }
        }
    }
`;

const ChangeCourse = props => {

    let { loading, error, fetchCourses } = props.data;

    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogContent>
          { loading && <p>Carregando...</p> }
          { error && <p>Error :(</p> }
          {
              fetchCourses && fetchCourses.map(course => 
                  <div style={{ display: 'flex', minWidth: 270 }}>
                      <div>
                          <img src={course.image} />
                      </div>
                      <div style={{ width: '100%'}}>
                          {
                              course.levels.map(level => 
                                <div style={{ marginLeft: 24}}>
                                    <p style={{ fontWeight: 'bold'}}>{level.name}</p>
                                    {
                                        level.units.map(unit => {
                                            
                                            let icon = <CheckIcon style={{ position: 'absolute', right: '20px' }} />;
                                            let onClick = () => props.onClickCourse(unit);
                                            let cursor = 'pointer'

                                            if(unit.locked ) {
                                                icon = <LockIcon style={{ position: 'absolute', right: '20px' }} />;
                                                onClick = () => {};
                                                cursor = 'initial';
                                            }

                                            return (
                                                <div onClick={onClick} style={{ padding: '12px 0px 12px 0px', borderBottom: '1px solid #CCC', cursor: cursor }}>
                                                    {unit.name}
                                                    { icon }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                              )
                          }
                      </div>
                  </div>
              )
          }
        </DialogContent>
      </Dialog>
    )
}

export default graphql(FETCH_COURSES)(ChangeCourse);