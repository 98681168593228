import React, {useState} from 'react';
import './LoginPage.css';
import Logo from '../../assets/logo.png';
import { Button, TextField, Card, Select, MenuItem, FormControl, InputLabel, CircularProgress, Grid, FormHelperText } from '@material-ui/core';
import { withFormik } from 'formik';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import client from '../../apollo-client'
import { withAppContext } from '../../app-context';
import * as Yup from 'yup';

const FETCH_SCHOOLS = gql`
    {
        fetchSchools {
            id name
        }
    }
`;

const LoginPage = props => {

    const { values, errors, handleChange, handleBlur, handleSubmit, isSubmitting } = props;

    const { loading, error, data } = useQuery(FETCH_SCHOOLS);

    return (
        <div className="LoginPage">
            <div className="login">
                <img src={Logo} alt="Logo" style={{ height: 200 }} />
                <Card className="loginCard">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth error={errors.school_id}>
                                <InputLabel htmlFor="school_id" shrink={values.school_id}>
                                    { !data ? 'Carregando unidades..' : 'Unidade'}
                                </InputLabel>
                                <Select
                                    native
                                    disabled={!data}
                                    inputProps={{
                                        name: 'school_id',
                                        id: 'school_id',
                                        onChange: handleChange,
                                        value: values.school_id
                                    }}
                                >
                                    <option value="" />
                                    {
                                        data && data.fetchSchools.map((row) => (
                                                <option value={row.id}>{row.name}</option>
                                        ))
                                    }
                                </Select>
                                {errors.school_id && (
                                    <FormHelperText>{errors.school_id}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Código de matricula"
                                    type="text"
                                    name="matricula"
                                    value={values.matricula}
                                    onChange={handleChange}
                                    error={errors.matricula || false}
                                    helperText={errors.matricula}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Senha"
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    error={errors.password || false}
                                    helperText={errors.password}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <div className="wrapper">
                        <Button
                            disabled={isSubmitting || !data}
                            onClick={handleSubmit}
                            variant="contained" 
                            color="primary">
                                { isSubmitting && <CircularProgress size={12} style={{ marginRight: 8 }} />}
                                Entrar
                        </Button>

                        {/* <Button color="primary">Esqueceu sua senha?</Button> */}
                    </div>

                </Card>
            </div>
        </div>
    );
}

const MUTATION_AUTH = gql`
    mutation Auth($login: String!, $password: String!, $school_id: Int) {
        auth(login: $login, password: $password, school_id: $school_id)
    }
`;

const auth = (values, {props, setSubmitting}) => {

    client.mutate({
        mutation: MUTATION_AUTH,
        variables: {
            login: values.matricula,
            password: values.password,
            school_id: values.school_id
        }
    }).then((result) => {

        let { data: { auth } } = result;

        props.appContext.updateToken(auth);

        setSubmitting(false);

        props.history.push('/');

    }).catch((error) => {
        props.appContext.setMessage(error.graphQLErrors[0].message)
        setSubmitting(false);
    });
}

export default withAppContext(withFormik({
    enableReinitialize: true,
    validationSchema: () => {
        return Yup.object().shape({
            school_id: Yup.number().required('Informe a unidade'),
            matricula: Yup.string().required('Informe a matrícula'),
            password: Yup.string().required('Informe a senha'),
        })
    },
    handleSubmit: auth
})(LoginPage));
