import React from 'react';

export const AppContext = React.createContext({
    getInfoUser: () => {},
    updateToken: (token) => {},
    setMessage: (message) => {}
});
  
export const withAppContext = (Component) => {
    return (props) => (
        <AppContext.Consumer>
            {(values) => <Component {...props} appContext={ values } />}
        </AppContext.Consumer>
    )
}