import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const checkToken = () => {
    return localStorage.getItem("token") !== null;
}

const PrivateRoute = ({ component: Component, ...remain}) => (
    <Route 
        {...remain} 
        render={ (props) => (
        checkToken() ? <Component {...props} {...remain}  /> 
        : <Redirect
            to={{
                pathname: '/login',
                state: { referrer: props.location }
            }}
        />
    )} />

)

export default PrivateRoute