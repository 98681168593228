import React from 'react';
import { AppBar, Toolbar, Grid, Typography, Card, CardContent, IconButton } from '@material-ui/core'
import Logo from '../../assets/inline_logo.png';
import EmptyPicture from '../../assets/empty_picture.png';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import DetailCourse from './DetailCourse';
import EditIcon from '@material-ui/icons/Edit';
import ChangeCourse from './ChangeCourse';

const FETCH_ME = gql`
    query{
        fetchMe {
            avatar
            document
            name
            email
            first_access
            current_course_unit_id
        }
    }
`;

const handleOpenSettings = props => {
    props.history.push("/settings");
};

const HomePage = props => {

    let { loading, error, fetchMe } = props.data;

    if (loading) return <p>Carregando...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <>
        <AppBar position="static" color="primary">
            <Toolbar style={{ backgroundColor: '#FFF' }}>
                <img src={Logo} alt="Logo" style={{ margin: '0 auto', height: 40 }} />
                <section className={{ marginLeft: 'auto', marginRight: -12}}>
                    <img onClick={() => handleOpenSettings(props)} src={fetchMe.avatar ? fetchMe.avatar : EmptyPicture} style={{ width: 45, height: 45, borderRadius: '50%', cursor: 'pointer' }} />
                </section>
            </Toolbar>
        </AppBar>
        <Grid container spacing={3} style={{ marginTop: 24 }}>
            <Grid item xs={12} lg={8} style={{ margin: '0 auto', textAlign: 'left' }}>
                <DetailCourse course_unit_id={fetchMe.current_course_unit_id} history={props.history} />
            </Grid>
        </Grid>
    </>
  );
}

export default graphql(FETCH_ME)(HomePage);
